import { Link, Typography } from "@mui/material";

export default function IconLink({ href, children, variant = "body2", icon = null, ...rest }) {
    return (
        icon || variant ?
            <Link href={href} sx={rest.sx} width="fit-content" {...rest} >
                <Typography variant={variant} sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                    {icon}
                    {children}
                </Typography>
            </Link>
            :
            <Link href={href} variant="body2" sx={rest.sx} width="fit-content" {...rest}>
                {children}
            </Link>
    );
}